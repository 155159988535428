.vbox-container {
  padding: 0 !important;
}

.vbox-content {
  padding: 0 !important;
}

body {
  @apply bg-gray-200;
}
