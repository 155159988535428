.button {
	border: 0;
	background: transparent;
	box-sizing: border-box;
	width: 0;
	height: 74px;

	border-color: transparent transparent transparent #d1d5db;
	transition: 100ms all ease;
	cursor: pointer;

	border-style: solid;
	border-width: 37px 0 37px 60px;
}
